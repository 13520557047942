import { RESET_TOURS } from '../action-types';

const initialState = {
    tours: [],
};

function tourReducer(state = initialState, { type, payload }) {
    switch (type) {
        case RESET_TOURS:
            return {
                tours: payload,
            };
        default:
            return state;
    }
}

export default tourReducer;
