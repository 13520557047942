import { useState, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";

import { AppContext } from '../contexts/AppContext';
import LocalizationContext from '../contexts/LocalizationContext';
import formatDistance from '../formatters/formatDistance';
import formatDuration from '../formatters/formatDuration';
import formatClimb from '../formatters/formatClimb';
import formatTime from '../formatters/formatTime';

import routes from '../absolutes/routes';
import { toursSelector } from '../store/selectors';
import { CONFIRM_PARTICIPATION, CANCEL_PARTICIPATION, RESET_TOURS } from '../store/action-types';
import rpcRequest from '../requests/rpc-request';
import nowYmd from '../utils/nowYmd';
import classNames from '../utils/classNames';

import Stack from '../components/Stack';

function TourDetail() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { setHeader } = useContext(AppContext);
    const { t, formatDate } = useContext(LocalizationContext);
    const { tourId } = useParams();
    const tours = useSelector(toursSelector);

    const now = nowYmd();
    const tour = tours.find(({ id }) => id === tourId);

    const [busy, setBusy] = useState(false);

    useEffect(() => {
        setHeader({
            title: t('tour'),
            returnPage: routes.home,
        });
    }, [setHeader, t, tour.title]);

    if (!tour) {
        // @TODO: Signal an error
        return (<></>);
    }

    function handleConfirm() {
        setBusy(true);

        const payload = {
            tour_id: tour.id,
        };

        rpcRequest('CONFIRM_TOUR_PARTICIPATION', payload).then(response => {

            setBusy(false);

            dispatch({
                type: CONFIRM_PARTICIPATION,
                payload: response
            });

            dispatch({
                type: RESET_TOURS,
                payload: response.tours,
            });

            history.push(routes.home);
        });
    }

    function handleCancel() {
        setBusy(true);

        const payload = {
            tour_id: tour.id,
        };

        rpcRequest('CANCEL_TOUR_PARTICIPATION', payload).then(response => {

            setBusy(false);

            dispatch({
                type: CANCEL_PARTICIPATION,
                payload: response
            });

            dispatch({
                type: RESET_TOURS,
                payload: response.tours,
            });

            history.push(routes.home);
        });
    }

    const properties = [
        {
            name: 'Datum',
            value: formatDate(tour.date),
        },
        {
            name: 'Uhrzeit',
            value: formatTime(tour.date),
        },
        {
            name: 'Treffpunkt',
            value: tour.location_long !== "" ? tour.location_long : tour.location,
        }
    ];

    if (tour.distance) {
        properties.push({
            name: 'Distanz',
            value: formatDistance(tour.distance),
        });
    }

    if (tour.duration) {
        properties.push({
            name: 'Dauer',
            value: `${formatDuration(tour.duration)}`,
        });
    }

    if (tour.climb) {
        properties.push({
            name: 'Anstieg',
            value: `${formatClimb(tour.climb)}`,
        });
    }

    const isUndecided = !['confirmed', 'cancelled', 'checked_in', 'checked_out'].includes(tour.participation);
    const isInProgress = tour.participation === 'checked_in';

    return (
        <>
            <div className="x-tour-item--zell">
                <h2 className="tour__title">{tour.title}</h2>
                <section className="properties">
                    {properties.map(({ name, value }, index) => (
                        <div className="property" key={`property_${index}`}>
                            <span className="property__name">{name}</span>
                            <span className="property__value">{value}</span>
                        </div>
                    ))}
                </section>
            </div>

            {tour.date < now && (
                <p>Diese Tour ist beendet.</p>
            )}

            <Participants tour={tour} />

            {tour.date >= now && (
                <>
                    <h2>Teilnahme</h2>
                    <Stack>
                        <Participation tour={tour} />
                        {!isInProgress && (
                            <>
                                {isUndecided && (<button type="button" className="button button--confirm" disabled={busy} onClick={handleConfirm}>( ✓ ) Teilnahme zusagen</button>)}
                                {isUndecided && (<button type="button" className="button button--cancel" disabled={busy} onClick={handleCancel}>( ✗ ) Teilnahme absagen</button>)}
                            </>
                        )}
                    </Stack>
                </>
            )}
        </>
    );
}

const getTextPast = (tour) => {
    if (tour.confirmations) {
        if (tour.confirmations > 1) {
            return `An dieser Tour haben ${tour.confirmations} Personen teilgenommen.`;
        } else {
            return 'An dieser Tour hat eine Person teilgenommen.';
        }
    } else {
        return 'An dieser Tour hat niemand teilgenommen.';
    }
};

const getTextUpcoming = (tour) => {
    if (tour.confirmations) {
        if (tour.confirmations > 1) {
            return `Es haben sich bisher ${tour.confirmations} Personen zu dieser Tour angemeldet.`;
        } else {
            return 'Es hat sich bisher eine Person zu dieser Tour angemeldet.';
        }
    } else {
        return 'Es hat sich bisher noch niemand zu dieser Tour angemeldet.';
    }
};

function Participation({ tour }) {

    const isUndecided = !['confirmed', 'cancelled', 'checked_in', 'checked_out'].includes(tour.participation);
    const isInProgress = tour.participation === 'checked_in';

    let icon = '';
    let text;

    if (isUndecided) {
        text = 'Du hast noch keine Entscheidung bezüglich deiner Teilnahme abgegeben.';
    } else if (isInProgress) {
        text = 'Du nimmst an dieser Tour teil. Bitte denke daran dich wieder abzumelden sobald du die Tour beendet hast.';
        icon = 'checked_in';
    } else if (tour.participation === 'cancelled') {
        text = 'Du hast deine Teilnahme abgesagt.';
        icon = 'cancelled';
    } else if (tour.participation === 'confirmed') {
        text = 'Du hast deine Teilnahme zugesagt.';
        icon = 'confirmed';
    } else if (tour.participation === 'checked_out') {
        text = 'Du hast teilgenommen.';
        icon = 'checked_out';
    }

    const classesRoot = classNames(
        'tour-participation',
        {
            [`tour-participation--${icon}`]: icon !== ''
        }
    );

    const classesIcon = classNames(
        'tour__participation-icon',
        {
            [`tour__participation-icon--${icon}`]: icon !== ''
        }
    );

    return (
        <div className={classesRoot}>
            <span>
                <span className={classesIcon}></span>
            </span>
            <p>{text}</p>
        </div>
    );
}

function Participants({ tour }) {
    return (tour.date < nowYmd())
        ? (<p className="text--smaller">{getTextPast(tour)}</p>)
        : (<p className="text--smaller">{getTextUpcoming(tour)}</p>);
}

export default TourDetail;
