import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from "react-router-dom";
import LocalizationContext from '../contexts/LocalizationContext';
import { toursSelector } from '../store/selectors';
import nowYmd from '../utils/nowYmd';
import routes from '../absolutes/routes';
import { CATEGORY_ID_MAMBACH } from '../absolutes/tour-categories';
import formatDate from '../formatters/formatDate';

function ToursDashlet() {

    const history = useHistory();
    const tours = useSelector(toursSelector) || [];
    const { t } = useContext(LocalizationContext);

    const now = nowYmd();
    let latestTours = [];

    const mambachTours = tours.filter(
        tour => (tour.category_id === CATEGORY_ID_MAMBACH)
    );

    const upcomingTours = mambachTours.filter(
        tour => tour.date >= now
    );

    upcomingTours.sort((a, b) => a.date === b.date ? 0 : a.date < b.date ? -1 : 1);

    const pastTours = mambachTours.filter(
        tour => tour.date < now
    );

    pastTours.sort((a, b) => a.date === b.date ? 0 : a.date < b.date ? 1 : -1);

    latestTours = latestTours.concat(upcomingTours);
    latestTours = latestTours.concat(pastTours);

    const handleClick = (tour) => {
        history.push(generatePath(routes.tourDetail, {
            tourId: tour.id
        }))
    };

    const tourClass = (tour) => {
        switch (true) {
            case (tour.date < now):
                return 'list-item--past';
            default:
                return '';
        }
    };

    return (
        <section>
            <h2 hidden>{t('mambach_tours')}</h2>
            <ul className="list list--condensed">
                {latestTours.map(tour => {
                    return (
                        <li key={`zell_tour_${tour.id}`} className={`list__item ${tourClass(tour)}`} onClick={() => handleClick(tour)}>
                            <span className="tour">
                                <span className="tour__title-wrapper">
                                    <span className="tour__title  tour__title--mambach"><time>{formatDate(tour.date, 'j. F')}</time>: {tour.title}</span>
                                    <span className="tour__participation">
                                        <span className="tour__participation-count badge">{tour.confirmations}</span>
                                        <span className={`tour__participation-icon tour__participation-icon--${tour.participation}`}></span>
                                    </span>
                                </span>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

export default ToursDashlet;
