import { useContext } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, generatePath } from "react-router-dom";
import LocalizationContext from '../contexts/LocalizationContext';
import { toursSelector } from '../store/selectors';
import nowYmd from '../utils/nowYmd';
import classNames from '../utils/classNames';
import routes from '../absolutes/routes';
import { CATEGORY_ID_ZELL } from '../absolutes/tour-categories';
import formatDate from '../formatters/formatDate';

function ToursDashlet() {

    const history = useHistory();
    const tours = useSelector(toursSelector) || [];
    const { t } = useContext(LocalizationContext);

    const now = nowYmd();
    let latestTours = [];

    const zellTours = tours.filter(
        tour => (tour.category_id === CATEGORY_ID_ZELL)
    );

    latestTours = zellTours;
    latestTours.sort((a, b) => a.date === b.date ? 0 : a.date < b.date ? -1 : 1);

    const handleClick = (tour) => {
        history.push(generatePath(routes.tourDetail, {
            tourId: tour.id
        }))
    };

    const tourClass = (tour) => classNames({
        'tour-item--zell--past': (tour.date < now)
    });

    return (
        <section className="section--tours-zell">
            <h2 hidden>{t('zell_tours')}</h2>
            <ul className="tour-list--zell">
                {latestTours.map(tour => {
                    return (
                        <li key={`zell_tour_${tour.id}`} className={`tour-item--zell ${tourClass(tour)}`} onClick={() => handleClick(tour)}>
                            <span className="tour">
                                <span className="tour__properties">
                                    <time>{formatDate(tour.date, 'j. F')}</time>
                                </span>
                                <span className="tour__title-wrapper">
                                    <span className="tour__title tour__title--zell">{tour.title}</span>
                                    <span className="tour__participation">
                                        <span className="tour__participation-count badge">{tour.confirmations}</span>
                                        <span className={`tour__participation-icon tour__participation-icon--${tour.participation}`}></span>
                                    </span>
                                </span>
                                <span className="tour__location">{tour.location}</span>
                            </span>
                        </li>
                    );
                })}
            </ul>
        </section>
    );
}

export default ToursDashlet;
