import { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";

import routes from '../absolutes/routes';
import rpcRequest from '../requests/rpc-request';
import { SET_USER, RESET_TOURS, RESET_NEWS } from '../store/action-types';
import LocalizationContext from '../contexts/LocalizationContext';

function Startup() {

    const dispatch = useDispatch();
    const history = useHistory();
    const {
        t, getLanguage,
    } = useContext(LocalizationContext);

    const [hint, showHint] = useState('');
    const [language ] = useState(getLanguage());

    useEffect(() => {
        const token = window.localStorage.getItem('mtbzellerbergland-user-token');

        if (token) {

            showHint(t('startup_login'));

            const payload = {
                token,
            };
            rpcRequest('SIGN_IN', payload).then((response) => {

                if (!response) {
                    showHint(t('startup_login_failed'));
                    window.localStorage.removeItem('mtbzellerbergland-user-token');
                    return;
                }

                const { token, user } = response;

                if (token) {

                    dispatch({
                        type: SET_USER,
                        payload: {
                            token,
                            user,
                        },
                    });
                    dispatch({
                        type: RESET_TOURS,
                        payload: response.tours,
                    });
                    dispatch({
                        type: RESET_NEWS,
                        payload: response.news,
                    });

                    history.push(routes.home);

                } else {
                    showHint(t('startup_login_failed'));
                    window.localStorage.removeItem('mtbzellerbergland-user-token');
                }
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, history]);

    useEffect(() => {
        document.documentElement.lang = language;
    }, [language]);

    return (
        <>
            <h1 className="brand">MTB Zeller Bergland</h1>

            <p className="lead">{t('startup_welcome_title')}</p>

            <section className="intro">
                <p>{t('startup_welcome_intro')}</p>
                <p className="text--smaller">{t('startup_welcome_terms')}</p>
                {hint !== '' && (
                    <p>{hint}</p>
                )}
            </section>

            <div className="control">
                <p>{t('no_login_yet')}</p>
                <Link to={routes.registration} className="button">{t('registration')}</Link>
            </div>

            <div className="control">
                <Link to={routes.login} className="text--link text--smaller">{t('already_have_login')}</Link>
            </div>
        </>
    );
}
export default Startup;
