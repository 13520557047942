import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHECK_IN, RESET_TOURS } from '../store/action-types';
import rpcRequest from '../requests/rpc-request';

function CheckIn({ tour }) {

    const dispatch = useDispatch();
    const [busy, setBusy] = useState(false);

    if (!tour) {
        return <></>;
    }

    function handleCheckIn() {
        setBusy(true);

        const payload = {
            tour_id: tour.id,
        };

        rpcRequest('CHECK_IN', payload).then(response => {

            setBusy(false);

            dispatch({
                type: CHECK_IN,
                payload: response
            });

            dispatch({
                type: RESET_TOURS,
                payload: response.tours,
            });
        });
    }

    return (
        <section className="section">
            <h2 hidden>Check in</h2>
            <p>Die Tour "{tour.title}" findet heute statt. Melde dich hier an, wenn du bei der Tour startest. So können wir am Ende nachvollziehen, ob alle Personen wieder sicher zurückgekehrt sind.</p>
            <button type="button" className="button" disabled={busy} onClick={handleCheckIn}>Anmelden</button>
        </section>
    )
}

export default CheckIn;
