import { useState, createContext  } from 'react';

export const AppContext = createContext({
    title: "MTB Zeller Bergland",
    returnPage: "",
});

function AppWrapper({ children }) {

    const [header, setHeader] = useState({
        title: "MTB Zeller Bergland",
        returnPage: "",
    });

    return (
        <AppContext.Provider value={{ header, setHeader }}>
            {children}
        </AppContext.Provider>
    );
}

export { AppWrapper };
