const routes = {
    home: "/",
    registration: "/registration",
    login: "/login",
    status: "/status",
    service: "/service/:bikeId",
    settings: "/settings",
    settingsAccount: "/settings/account",
    settingsApplication: "/settings/application",
    settingsLocalization: "/settings/localization",
    bikes: "/bikes",
    blankBike: "/new-bike",
    detailBike: "/bike/:bikeId",
    bikePart: "/bike/:bikeId/part/:partId",
    bikePartUsage: "/bike/:bikeId/part/:partId/usage",
    specifyPart: "/bike/:bikeId/specify-part/:partId",
    blankPart: "/bike/:bikeId/new-part",
    tourDetail: "/tour/:tourId",
};

export default routes;
