import classNames from "../utils/classNames";

function ControlString({ name, value, label, required, autoFocus, datalist, placeholder, onChange, type = "text", hint = "" }) {
    let inputMode;
    if (type === "email") {
        inputMode = "email";
    }
    return (
        <div className="control">
            <label htmlFor={name} className={classNames('label', { 'label--required': required })}>{label}</label>
            <input type={type} placeholder={placeholder} inputMode={inputMode} id={name} value={value} list={datalist ? `datalist_${name}` : undefined} autoFocus={autoFocus} required={required} className="input--string" onChange={onChange} />
            {hint !== "" && (
                <p className="control__hint">{hint}</p>
            )}
            {datalist && (
                <datalist id={`datalist_${name}`}>
                    {datalist.map((value, index) => (
                        <option key={`datalist_${name}_${index}`} value={value} />
                    ))}
                </datalist>
            )}
        </div>
    );
}

export default ControlString;
