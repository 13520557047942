function formatTime(value, format) {
    let dateValue;
    if (value.length === 10) {
        dateValue = value + 'T12:00:00';
    } else {
        dateValue = value.substr(0, 10) + "T" + value.substr(11);
    }
    const date = new Date(dateValue);
    let s = format ?? 'H:i';
    s = s.replace(/H/g, String(date.getHours()).padStart(2, '0'));
    s = s.replace(/i/g, String(date.getMinutes()).padStart(2, '0'));
    return s;
};

export default formatTime;
