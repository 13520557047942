import id from "../utils/id";

function request(method, payload) {

    const token = localStorage.getItem('mtbzellerbergland-user-token');

    const data = {
        jsonrpc: '2.0',
        method,
        params: payload,
        id: id(),
    };
    const url = '/rpc';
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    };
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    const options = {
        method: 'POST',
        mode: 'cors',
        headers,
        body: JSON.stringify(data),
    };

    return fetch(url, options)
        .then(response => response.json())
        .then(jsonrpc => jsonrpc.result);
}

export default request;
