import { useSelector } from 'react-redux';

import { newsSelector } from '../store/selectors';
import formatDate from '../formatters/formatDate';

function NewsDashlet() {

    const news = useSelector(newsSelector)?.[0];

    if (!news) {
        return (<></>);
    }

    return (
        <article className="news-item">
            <div>
                <h2 className="news-item__title">{news.title}</h2><time className="news-item__date">{formatDate(news.date, 'j. F')}</time>
            </div>
            <p className="news-item__text">{news.text}</p>
        </article>
    );
}

export default NewsDashlet;
