import { useEffect, useContext } from 'react';
import { Link } from "react-router-dom";
import { AppContext } from '../contexts/AppContext';
import IconClose from '../icons/IconClose';

const Header = () => {

	const { header } = useContext(AppContext);
	const { title, returnPage } = header;

	const className = 'header-title'; // title === 'MTB Zeller Bergland' ? 'header-title--brand' : 'header-title';

	useEffect(() => {
		const $body = document.querySelector('body');
		if (title === "") {
			$body.className = "page--no-header";
		} else {
			$body.className = "";
		}
	}, [title]);

	return (
		<>
			<header>
				{title === 'Cyclido' ? (
					<h1 className={className}>{title}</h1>
				) : (
					<>
						{title !== "" && (
							<h1 className={className}>{title}</h1>
						)}
						{!!returnPage && (
							<Link to={returnPage}><IconClose /></Link>
						)}
					</>
				)}
			</header>
		</>
	)
};

export default Header;
