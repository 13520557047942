function classNames() {
    const classes = [];
    for (let arg of arguments) {
        if (typeof arg === 'object') {
            Object.entries(arg).forEach(([className, doPick]) => {
                if (doPick) {
                    classes.push(className);
                }
            });
        } else if (typeof arg === 'string') {
            classes.push(arg);
        }
    }
    return classes.join(' ');
}

export default classNames;
