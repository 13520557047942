function IconHome() {
    return (
        <svg className="icon-home" width="17px" height="17px" version="1.1" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path d="m2.7272 8.6749 9.7728-7.602 9.7728 7.602v15.612h-19.546z" fill="none" strokeWidth="1.5" />
            </g>
        </svg>
    );
}

export default IconHome;
