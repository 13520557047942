import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { CHECK_OUT, RESET_TOURS } from '../store/action-types';
import rpcRequest from '../requests/rpc-request';

function CheckOut({ tour }) {

    const dispatch = useDispatch();
    const [busy, setBusy] = useState(false);

    if (!tour) {
        return <></>;
    }

    function handleCheckOut() {
        setBusy(true);

        const payload = {
            tour_id: tour.id,
        };

        rpcRequest('CHECK_OUT', payload).then(response => {

            setBusy(false);

            dispatch({
                type: CHECK_OUT,
                payload: response
            });

            dispatch({
                type: RESET_TOURS,
                payload: response.tours,
            });
        });
    }

    return (
        <section className="section">
            <h2 hidden>Check out</h2>
            <p>Du hast deinen Start an der heutigen Tour "{tour.title}" angemeldet. Bitte melde dich wieder ab sobald du wieder zurückgekehrt bist.</p>
            <button type="button" className="button" disabled={busy} onClick={handleCheckOut}>Abmelden</button>
        </section>
    )
}

export default CheckOut;
