import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { useSelector } from 'react-redux';

import { userSelector } from './store/selectors';

import routes from './absolutes/routes';

import Header from './components/Header';
import Navbar from './components/Navbar';

import Startup from './pages/Startup';
import Home from './pages/Home';
import Registration from './pages/Registration';
import Login from './pages/Login';
import TourDetail from './pages/TourDetail';
import Settings from './pages/Settings';
import SettingsAccount from './pages/settings/Account';
import SettingsApplication from './pages/settings/Application';

import { LocalizationWrapper } from './contexts/LocalizationContext';
import { AppWrapper } from "./contexts/AppContext";

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {

    const user = useSelector(userSelector);
    const isLoggedIn = !!user;

    return (
        <AppWrapper>
            <LocalizationWrapper>
                <Router>
                    <RouteChange>
                        {isLoggedIn ? (
                            <>
                                <Header />
                                <main className="main--logged-in">
                                    <Switch>
                                        <Route path={routes.tourDetail} component={TourDetail} />
                                        <Route path={routes.settingsAccount} component={SettingsAccount} />
                                        <Route path={routes.settingsApplication} component={SettingsApplication} />
                                        <Route path={routes.settings} component={Settings} />
                                        <Route path={routes.home} component={Home} />
                                    </Switch>
                                </main>
                                <footer>
                                    <Navbar />
                                </footer>
                            </>
                        ) : (
                            <>
                                <main className="main--logged-out">
                                    <Switch>
                                        <Route path={routes.login} component={Login} />
                                        <Route path={routes.registration} component={Registration} />
                                        <Route component={Startup} />
                                    </Switch>
                                </main>
                            </>
                        )}
                    </RouteChange>
                </Router>
            </LocalizationWrapper>
        </AppWrapper>
    );
}

function RouteChange({ children }) {
    const location = useLocation();
    useEffect(() => {
        const $main = document.querySelector('main');
        if ($main) {
            $main.scrollTop = 0;
        }
    }, [location]);
    return children;
}

export default App;
