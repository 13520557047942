function formatDate(value, format) {
    let dateValue;
    if (value.length === 10) {
        dateValue = value + 'T12:00:00';
    } else {
        dateValue = value.substr(0, 10) + "T" + value.substr(11);
    }
    const date = new Date(dateValue);
    let s = format ?? 'd.m.Y';

    s = s.replace(/d/g, String(date.getDate()).padStart(2, '0'));
    s = s.replace(/j/g, date.getDate());
    s = s.replace(/m/g, String(date.getMonth() + 1).padStart(2, '0'));
    s = s.replace(/Y/g, date.getFullYear());
    s = s.replace(/H/g, String(date.getHours()).padStart(2, '0'));
    s = s.replace(/i/g, String(date.getMinutes()).padStart(2, '0'));
    s = s.replace(/F/g, getMonthText(date.getMonth() + 1));

    return s;
}

function getMonthText(month) {
    switch (month) {
        case 1:
            return 'Januar';
        case 2:
            return 'Februar';
        case 3:
            return 'März';
        case 4:
            return 'April';
        case 5:
            return 'Mai';
        case 6:
            return 'Juni';
        case 7:
            return 'Juli';
        case 8:
            return 'August';
        case 9:
            return 'September';
        case 10:
            return 'Oktober';
        case 11:
            return 'November';
        case 12:
            return 'Dezember';
        default:
            return '';
    }
}

export default formatDate;
