import { NavLink as Link } from "react-router-dom";

import IconHome from '../icons/IconHome';
import IconSettings from '../icons/IconSettings';

const Navbar = () => {
	return (
		<>
			<ul className="navbar">
				<li className="navbar__item"><Link to="/" activeClassName="active" exact><IconHome /></Link></li>
				<li className="navbar__item"><Link to="/settings"><IconSettings /></Link></li>
			</ul>
		</>
	);
};

export default Navbar;
