//const DAYS_PER_WEEK = 7;
const HOUS_PER_DAY = 24;
const MINUTES_PER_HOUR = 60;

function formatDurationUnit(value) {
    const parts = [];
    let v = value;

    const d = Math.floor(v / (MINUTES_PER_HOUR * HOUS_PER_DAY));
    v = v % (MINUTES_PER_HOUR * HOUS_PER_DAY);
    const h = Math.floor(v / MINUTES_PER_HOUR);
    v = v % (MINUTES_PER_HOUR);
    const m = v % MINUTES_PER_HOUR;

    if (d > 0) {
        parts.push(
            {
                value: d,
                type: "value"
            },
            {
                value: "d",
                type: "unit"
            }
        );
    }
    if (h > 0) {
        parts.push(
            {
                value: h,
                type: "value"
            },
            {
                value: "h",
                type: "unit"
            }
        );
    }
    if (m > 0 || h === 0) {
        parts.push(
            {
                value: m,
                type: "value"
            },
            {
                value: "m",
                type: "unit"
            }
        );
    }

    return parts;
}

function formatDuration(value) {
    const parts = [];
    let v = value;
    /*
    const w = Math.floor(v / (MINUTES_PER_HOUR * HOUS_PER_DAY * DAYS_PER_WEEK));
    v = v % (MINUTES_PER_HOUR * HOUS_PER_DAY * DAYS_PER_WEEK);
    */
    const d = Math.floor(v / (MINUTES_PER_HOUR * HOUS_PER_DAY));
    v = v % (MINUTES_PER_HOUR * HOUS_PER_DAY);
    const h = Math.floor(v / MINUTES_PER_HOUR);
    v = v % (MINUTES_PER_HOUR);
    const m = v % MINUTES_PER_HOUR;
    /*
    if (w > 0) {
        parts.push(`${w}w`);
    }
    */
    if (d > 0) {
        parts.push(`${d}d`);
    }
    if (h > 0) {
        parts.push(`${h}h`);
    }
    if (m > 0 || h === 0) {
        parts.push(`${m}m`);
    }
    return parts.join('');
}

export { formatDurationUnit };
export default formatDuration;
