import formatterDate from "../formatters/formatDate";

const DEFAULT_LANGUAGE = 'de';
const DEFAULT_DATE_FORMAT = 'dd_mm_yyyy';

function useLocalization() {

    const language = DEFAULT_LANGUAGE;
    const dateFormat = DEFAULT_DATE_FORMAT;

    const texts = require(`../locales/texts.${language}.json`);

    const getLanguage = () => language;

    const t = (key) => {
        return texts[key];
    }

    const formatDate = (value) =>
        formatterDate(
            value,
            dateFormat === 'mm_dd_yyyy' ? 'm/m/Y' : 'd.m.Y'
        );

    return {
        t,
        getLanguage,
        formatDate,
    };
}

export default useLocalization;
