import { createContext } from 'react';
import useLocalization from '../hooks/useLocalization';

const LocalizationContext = createContext({});

export default LocalizationContext;

function LocalizationWrapper({ children }) {

    const localizationContext = useLocalization();

    return (
        <LocalizationContext.Provider value={localizationContext}>
            {children}
        </LocalizationContext.Provider>
    );
}

export { LocalizationWrapper };
