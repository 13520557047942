import { SET_USER, CLEAR_USER } from '../action-types';

const initialState = {
    user: undefined,
};

// @FIXME: Find better place
function setToken(token) {
    window.localStorage.setItem('mtbzellerbergland-user-token', token);
}
function clearToken() {
    window.localStorage.removeItem('mtbzellerbergland-user-token');
}

function appReducer(state = initialState, { type, payload }) {
    switch (type) {
        case SET_USER:
            setToken(payload.token);
            return {
                ...state,
                user: payload.user,
            };
        case CLEAR_USER:
            clearToken();
            return {
                ...state,
                user: undefined,
            };
        default:
            return state;
    }
}

export default appReducer;
