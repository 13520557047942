import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import Stack from '../components/Stack';
import CheckIn from '../components/CheckIn';
import CheckOut from '../components/CheckOut';
import ZellToursDashlet from '../dashlets/ZellToursDashlet';
import MambachToursDashlet from '../dashlets/MambachToursDashlet';
import NewsDashlet from '../dashlets/NewsDashlet';

import { CATEGORY_ID_ZELL } from '../absolutes/tour-categories';
import { AppContext } from '../contexts/AppContext';
import { toursSelector, newsSelector } from '../store/selectors';

import nowYmd from '../utils/nowYmd';

function Home() {

    const tours = useSelector(toursSelector);
    const news = useSelector(newsSelector);
    const { setHeader } = useContext(AppContext);

    useEffect(() => {
        setHeader({ title: 'MTB Zeller Bergland' });
    }, [setHeader]);

    const todaysTour = tours?.find(tour => tour.category_id === CATEGORY_ID_ZELL && tour.date.substr(0, 10) === nowYmd());
    const showCheckIn = todaysTour && todaysTour.participation === 'confirmed';
    const showCheckOut = todaysTour && todaysTour.participation === 'checked_in';
    const showNews = news?.length > 0;

    return (
        <Stack>
            <ZellToursDashlet key="dashlet_zell_tours" />
            {showCheckIn && (<CheckIn tour={todaysTour} key="dashlet_check_in" />)}
            {showCheckOut && (<CheckOut tour={todaysTour} key="dashlet_check_out" />)}
            {showNews && (<NewsDashlet key="dashlet_news" />)}
            <MambachToursDashlet key="dashlet_mambach_tours" />
        </Stack>
    );
}

export default Home;
