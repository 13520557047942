import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";

import routes from '../absolutes/routes';
import rpcRequest from '../requests/rpc-request';
import { SET_USER, RESET_TOURS } from '../store/action-types';
import LocalizationContext from '../contexts/LocalizationContext';

import Stack from '../components/Stack';
import ControlString from '../controls/control-string';

function Registration() {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useContext(LocalizationContext);

    const [busy, setBusy] = useState(false);

    const [showLegal, setShowLegal] = useState(true);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        setBusy(true);

        const payload = {
            name,
            email,
            password,
        };

        rpcRequest('REGISTER', payload).then((response) => {

            setBusy(false);

            const { token, user } = response;

            if (token) {

                setError('');

                dispatch({
                    type: SET_USER,
                    payload: {
                        token,
                        user,
                    },
                });

                dispatch({
                    type: RESET_TOURS,
                    payload: response.tours,
                });

                history.push(routes.home);
            } else {
                setError(t('form_error'));
            }
        });
    };

    return (
        <>
            <h1 className="brand"><Link to={routes.home}>MTB Zeller Bergland</Link></h1>
            <h2>{t('registration')}</h2>
            <Stack>
                {showLegal ? (
                    <>
                        <div className="form-group">
                            <Legal />
                        </div>
                        <div className="control">
                            <button type="button" className="button" onClick={() => setShowLegal(false)}>{t('accept')}</button>
                        </div></>
                ) : (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <ControlString name="name" value={name} label={t('name')} required autoFocus onChange={e => setName(e.target.value)} hint="Erforderlich; mind. 2 Zeichen." />
                            <ControlString name="email" type="email" value={email} label={t('email')} required onChange={e => setEmail(e.target.value)} hint="Erforderlich." />
                            <ControlString name="password" type="password" value={password} label={t('password')} required onChange={e => setPassword(e.target.value)} hint="Erforderlich; mind. 6 Zeichen." />
                        </div>
                        {error !== "" && (
                            <p className="form__error">{error}</p>
                        )}
                        <div className="control">
                            <button type="submit" className="button" disabled={busy}>{t('register')}</button>
                        </div>
                    </form>
                )}
                <div>
                    <Link to={routes.login} className="text--link text--smaller">{t('already_have_login')}</Link>
                </div>
            </Stack>
        </>
    )
}

function Legal() {

    const { t } = useContext(LocalizationContext);

    return (
        <>
            <h3>{t('data_protection_title')}</h3>
            <p>{t('data_protection_text')}</p>
            <p className="text--smaller">{t('data_protection_text_passwords')}</p>
        </>
    );
}

export default Registration;
