export const SET_USER = "SET_USER";
export const CLEAR_USER = "CLEAR_USER";

export const RESET_TOURS = "RESET_TOURS";
export const RESET_NEWS = "RESET_NEWS";

export const CONFIRM_PARTICIPATION = "CONFIRM_PARTICIPATION";
export const CANCEL_PARTICIPATION = "CANCEL_PARTICIPATION";
export const CHECK_IN = "CHECK_IN";
export const CHECK_OUT = "CHECK_OUT";
