function IconSettings() {
    return (
        <svg className="icon-settings" width="19px" height="19px" version="1.1" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <path transform="matrix(.25634 0 0 .25634 8.0669 6.2353)" d="m51.69 52.841c-7.9492 9.2157-12.676-9.7839-23.836-4.9299-11.161 4.8541-0.48536 21.267-12.647 20.798-12.161-0.46902-0.25388-16.011-11.007-21.71-10.754-5.6992-16.929 12.88-24.145 3.0794-7.2158-9.8006 12.359-10.181 10.11-22.142-2.2489-11.961-20.625-5.2054-17.462-16.958 3.1634-11.752 15.666 3.3152 23.615-5.9006 7.9492-9.2157-8.7899-19.371 2.3706-24.225 11.161-4.8541 7.1755 14.315 19.337 14.784 12.161 0.46901 9.6645-18.95 20.418-13.251 10.754 5.6992-6.718 14.535 0.49782 24.336 7.2158 9.8006 20.841-4.2591 23.09 7.7018 2.2489 11.961-15.553 3.8102-18.716 15.562-3.1634 11.752 16.324 13.639 8.375 22.855z" strokeWidth="5.8515" />
                <circle cx="12.5" cy="12.5" r="3.6201" strokeWidth="1.5" />
            </g>
        </svg>
    );
}

export default IconSettings;
