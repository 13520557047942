import { useEffect, useState, useContext } from 'react';
import { useDispatch } from 'react-redux'
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";

import routes from '../absolutes/routes';
import { AppContext } from '../contexts/AppContext';
import LocalizationContext from '../contexts/LocalizationContext';
import Stack from '../components/Stack';

import rpcRequest from '../requests/rpc-request';
import { CLEAR_USER } from '../store/action-types';

function Settings() {

    const { setHeader } = useContext(AppContext);
    const { t } = useContext(LocalizationContext);

    const dispatch = useDispatch();
    const history = useHistory();

    const [busy, setBusy] = useState(false);

    const items = [
        {
            title: t('account'),
            path: routes.settingsAccount,
        },
        {
            title: t('application'),
            path: routes.settingsApplication,
        },
    ];

    const handleLogout = () => {

        setBusy(true);

        const payload = {
            token: window.localStorage.getItem('mtbzellerbergland-user-token')
        };

        rpcRequest('LOG_OUT', payload).then(() => {
            setBusy(false);
            dispatch({
                type: CLEAR_USER,
            });
            history.push(routes.home);
        });
    };

    useEffect(() => {
        setHeader({
            title: "",
        });
    }, [setHeader]);

    return (
        <>
            <h1>{t('settings')}</h1>
            <Stack>
                <ul className="list list--interactive">
                    {items.map((item, index) => (
                        <li key={`settings_item_${index}`} className="list__item">
                            <Link to={item.path}>
                                <span className="setting__row">
                                    <span>{item.title}</span>
                                </span>
                            </Link>
                        </li>
                    ))}
                </ul>
                <div>
                    <a href="https://mtbzell.ambrosiustopor.name/" target="_blank">Webseite: mtbzell.ambrosiustopor.name</a>
                </div>
                <div className="control">
                    <button type="button" className="button button--secondary" onClick={handleLogout} disabled={busy}>{t('log_out')}</button>
                </div>
            </Stack>
        </>
    )
}

export default Settings;
