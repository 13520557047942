import { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';

import { AppContext } from '../../contexts/AppContext';
import LocalizationContext from '../../contexts/LocalizationContext';

import routes from '../../absolutes/routes';

import { userSelector, planSelector } from '../../store/selectors';

function AccountSettings() {

    const { setHeader } = useContext(AppContext);
    const { t, formatDate } = useContext(LocalizationContext);
    const user = useSelector(userSelector);
    const plan = useSelector(planSelector);

    const properties = [
        {
            name: t('member_since'),
            value: formatDate(user.creation_time),
        },
        {
            name: t('plan'),
            value: plan.name,
        },
    ];

    useEffect(() => {
        setHeader({
            title: t('account'),
            returnPage: routes.settings,
         });
    }, [setHeader, t]);

    return (
        <>
            <h2>{user.name}</h2>

            <section className="properties">
                {properties.map(({ name, value }, index) => (
                    <div className="property" key={`property_${index}`}>
                        <span className="property__name">{name}</span>
                        <span className="property__value">{value}</span>
                    </div>
                ))}
            </section>
        </>
    )
}

export default AccountSettings;
