function IconClose() {
    return (
        <svg className="icon-close" width="36px" height="20px" version="1.1" viewBox="0 0 40 25" xmlns="http://www.w3.org/2000/svg">
            <g fill="none">
                <path d="m6.0868 0.016406c-12.322 12.322 0 24.973 0 24.973" strokeWidth="1.5" />
                <path d="m33.791 0.016405c12.322 12.322 0 24.973 0 24.973" strokeWidth="1.5" />
            </g>
            <g>
                <g transform="matrix(1.4976 0 0 1.4976 -70.37 -68.218)" fill="none" strokeWidth="1.2353">
                    <path d="m56.891 57.349c6.8586-6.8586 6.9025-6.9025 6.9025-6.9025" />
                    <path d="m63.794 57.349-6.9025-6.9025" />
                </g>
            </g>
        </svg>
    );
}

export default IconClose;
