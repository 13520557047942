//const id = () => String(Math.random()).slice(2);

function id() {
    let seed = Date.now();
    if (typeof window.performance?.now === "function") {
        seed += performance.now();
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = (seed + Math.random() * 16) % 16 | 0;
        seed = Math.floor(seed/16);

        return (c === 'x' ? r : r & (0x3|0x8)).toString(16);
    });
}

export default id;
