function formatFloat(value, maxDigits = 3) {
    let v = parseFloat(value);

    const numberFormatter = new Intl.NumberFormat('en-CH');

    if (isNaN(v)) {
        v = '0';
    } else {
        if (maxDigits === 0) {
            v = v.toFixed(0);
        } else {
            for (let d = 3; d > 0; d--) {
                const s = v.toFixed(d);
                if (s.substr(-1) === "0" || d === maxDigits) {
                    v = s;
                    break;
                }
            }
        }
    }

    v = numberFormatter.format(v);

    return v;
}

export default formatFloat;
