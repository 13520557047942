import { RESET_NEWS } from '../action-types';

const initialState = {
    news: [],
};

function newsReducer(state = initialState, { type, payload }) {
    switch (type) {
        case RESET_NEWS:
            return {
                news: payload,
            };
        default:
            return state;
    }
}

export default newsReducer;
