import formatFloat from "./formatFloat";

function formatDistanceUnit(value) {
    let v;
    let unit;
    if (value >= 1000) {
        v = formatFloat(value/1000, 2);
        unit = "Tkm";
    } else {
        v = formatFloat(value, 1);
        unit = "km";
    }
    return {
        value: v,
        unit,
    };
}

function formatDistance(value) {
    if (value >= 1000) {
        return `${formatFloat(value/1000, 2)}Tkm`;
    } else {
        return `${formatFloat(value, 1)}km`;
    }
}

export { formatDistanceUnit };
export default formatDistance;
