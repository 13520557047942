import formatFloat from "./formatFloat";

function formatClimbUnit(value) {
    let v = parseFloat(value);
    let unit = "hm";
    if (isNaN(v)) {
        v = 0;
    } else if (v >= 10000) {
        v = formatFloat(v/1000, 1);
        unit = "Thm";
    } else {
        v = formatFloat(v, 0);
    }
    return {
        value: v,
        unit,
    };
}

function formatClimb(value) {
    const v = parseFloat(value);
    if (isNaN(v)) {
        return '0hm';
    } else if (v >= 10000) {
        return `${formatFloat(v/1000, 1)}Thm`;
    } else if (v >= 1000) {
        return `${formatFloat(v/1000, 2)}Thm`;
    } else {
        return `${formatFloat(v, 0)}hm`;
    }
}

export { formatClimbUnit };
export default formatClimb;
