import { createStore, combineReducers } from "redux";

import appReducer from "./reducers/app-reducer";
import tourReducer from "./reducers/tour-reducer";
import newsReducer from "./reducers/news-reducer";

const store = createStore(
  combineReducers({
    app: appReducer,
    tour: tourReducer,
    news: newsReducer,
  }),
  //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;
