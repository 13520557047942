const userSelector = state => state.app.user;
const planSelector = state => state.app.user.plan;
const toursSelector = state => state.tour.tours;
const newsSelector = state => state.news.news;

export {
    userSelector,
    planSelector,
    toursSelector,
    newsSelector,
};
