import { useState, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, Link } from "react-router-dom";

import routes from '../absolutes/routes';
import rpcRequest from '../requests/rpc-request';
import { SET_USER, RESET_TOURS } from '../store/action-types';
import LocalizationContext from '../contexts/LocalizationContext';

import ControlString from '../controls/control-string';

function Login() {

    const dispatch = useDispatch();
	const history = useHistory();
    const { t } = useContext(LocalizationContext);

    const [busy, setBusy] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const [error, setError] = useState("");

    const handleSubmit = (event) => {
        event.preventDefault();

        setBusy(true);

        const payload = {
            email,
            password,
        };

        rpcRequest('LOG_IN', payload).then((response) => {

            setBusy(false);

            if (!response) {
                setError(t('form_error'));
                return;
            }

            const { token, user } = response;

            if (token) {
                dispatch({
                    type: SET_USER,
                    payload: {
                        token,
                        user,
                    },
                });
                dispatch({
                    type: RESET_TOURS,
                    payload: response.tours,
                });
    
                history.push(routes.home);
            } else {
                setError(t('form_error'));
            }

        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <h1 className="brand"><Link to={routes.home}>MTB Zeller Bergland</Link></h1>
            <h2>{t('login')}</h2>

            <div className="form-group">
                <ControlString name="email" type="email" value={email} label={t('email')} required autoFocus onChange={e => setEmail(e.target.value)} />
                <ControlString name="password" type="password" value={password} label={t('password')} required onChange={e => setPassword(e.target.value)} />
            </div>

            {error !== "" && (
                <p className="form__error">{error}</p>
            )}

            <div className="control">
                <button type="submit" className="button" disabled={busy}>{t('log_in')}</button>
            </div>

            <div className="control">
                <Link to={routes.registration} className="text--link text--smaller">{t('no_login__link')}</Link>
            </div>
        </form>
    )
}

export default Login;
